// import { useState } from "react";
// @mui material components
// import { Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled } from "@mui/system";
// import { Theme } from "@mui/material/styles";
// import DesktopMacIcon from "@mui/icons-material/DesktopMac";
// import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import TextField from "@mui/material/TextField";
import { useState } from "react";
// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
// import MDButton from "template/components/MDButton";
import MDTypography from "template/components/MDTypography";

// import FormField from "components/elements/FormField";

// const customButtonStyles = ({
// 	functions: { pxToRem, rgba },
// 	borders: { borderWidth },
// 	palette: { transparent, secondary },
// 	typography: { size },
// }: Theme) => ({
// 	width: pxToRem(154),
// 	height: pxToRem(120),
// 	borderWidth: borderWidth[2],
// 	mb: 1,
// 	ml: 0.5,

// 	"&.MuiButton-contained, &.MuiButton-contained:hover": {
// 		boxShadow: "none",
// 		border: `${borderWidth[2]} solid ${transparent.main}`,
// 	},

// 	"&:hover": {
// 		backgroundColor: `${transparent.main} !important`,
// 		border: `${borderWidth[2]} solid ${secondary.main} !important`,
// 		color: rgba(secondary.main, 0.75),
// 	},

// 	"& .MuiSvgIcon-root": {
// 		fontSize: `${size["2xl"]} !important`,
// 	},
// });

// const OptionButtonStyle = styled("div")(() => ({
// 	position: "relative",
// 	"& .radio-container": {
// 		opacity: "1",
// 		zIndex: "99",
// 		cursor: "pointer",
// 		fontSize: "0",
// 		"& input[type='radio']": {
// 			position: "absolute",
// 			left: "0",
// 			top: "-5px",
// 			width: "100%",
// 			height: "150px",
// 			zIndex: "9",
// 			opacity: "0.01",
// 			fontSize: "0",
// 		},
// 	},
// 	"& .MuiInputLabel-root, fieldset": {
// 		display: "none",
// 	},
// }));

function Platform({ form }: any): JSX.Element {
	const {
		register,
		formState: { errors },
		getValues,
		setValue,
	} = form;
	const [platformType, setPlatformType] = useState(
		getValues("platform_type")
	);
	const onChangePlatformType = (e: any) => {
		setPlatformType(e.target.value);
		setValue("website_url", "");
		setValue("mobile_app_url", "");
		setValue("reason", "");
		// setValue("phone_number", "");
		// setValue("both_apple_google", false);
	};
	// const [mobileApps, setMobileApps] = useState(false);
	// const handleSetWebsite = () => {
	// 	setWebsite(!website);
	// };
	// const handleSetMobileApps = () => setMobileApps(!mobileApps);

	const integrationOptions = [
		{
			value: "Integrate Games in Mobile App",
			label: "Integrate Games in Mobile App",
		},
		{
			value: "Integrate Games in Website",
			label: "Integrate Games in Website",
		},
		{
			value: "Both, Mobile App and Website",
			label: "Both, Mobile App and Website",
		},
		{
			value: "Don't need integration in App or Website",
			label: "Don't need integration in App or Website",
		},
	];

	return (
		<MDBox paddingTop="40px" minHeight="330px">
			<MDBox width="100%" textAlign="center" mx="auto" my={4}>
				<MDBox mb={1}>
					<MDTypography variant="h5" fontWeight="regular">
						Specify your requirements
					</MDTypography>
				</MDBox>
				{/* <MDTypography
					variant="body2"
					color="text"
					style={{ maxWidth: 300, margin: "0 auto" }}
				>
					Goama has support for all below options
					<br /> p.s. You can change this later
				</MDTypography> */}
			</MDBox>
			<MDBox mt={2}>
				<Grid container spacing={0} justifyContent="center">
					{/* <Grid item xs={12} xl={3} md={4} lg={3} sm={4}>
						<OptionButtonStyle onClick={handleSetWebsite}>
							<div className="radio-container">
								<FormField
									register={register}
									type="radio"
									label="Platform type"
									name="platform_type"
									value="website"
									placeholder="Platform type"
								/>
							</div>

							<MDBox textAlign="center">
								<MDButton
									color="secondary"
									variant={
										getValues("platform_type") === "website"
											? "contained"
											: "outlined"
									}
									sx={customButtonStyles}
								>
									<DesktopMacIcon />
								</MDButton>
								<MDTypography variant="h6" sx={{ mt: 1 }}>
									Website
								</MDTypography>
							</MDBox>
						</OptionButtonStyle>
					</Grid>
					<Grid item xs={12} xl={3} lg={3} md={4} sm={4}>
						<OptionButtonStyle onClick={handleSetMobileApps}>
							<div className="radio-container">
								<FormField
									register={register}
									type="radio"
									label="Platform type"
									name="platform_type"
									value="mobile"
									placeholder="Platform type"
									checked
								/>
							</div>
							<MDBox textAlign="center">
								<MDButton
									color="secondary"
									variant={
										getValues("platform_type") === "mobile"
											? "contained"
											: "outlined"
									}
									sx={customButtonStyles}
								>
									<MobileFriendlyIcon />
								</MDButton>
								<MDTypography variant="h6" sx={{ mt: 1 }}>
									Mobile App
								</MDTypography>
							</MDBox>
						</OptionButtonStyle>
					</Grid> */}

					<Grid item xs={9} sm={4}>
						<MDBox sx={{ mb: 2 }}>
							<TextField
								{...register("platform_type")}
								select
								label="Integration Option"
								name="platform_type"
								// defaultValue={integrationOptions[0].value}
								// placeholder="Select department"
								onChange={onChangePlatformType}
								error={!!errors?.platform_type?.message}
								SelectProps={{
									native: true,
								}}
								// required
								fullWidth
							>
								{integrationOptions.map((option) => (
									<option
										key={option.value}
										value={option.value}
									>
										{option.label}
									</option>
								))}
							</TextField>
							<MDBox mt={0.75}>
								<MDTypography
									component="div"
									variant="caption"
									color="error"
									fontWeight="regular"
								>
									{errors?.platform_type?.message &&
										`* ${errors?.platform_type?.message}`}
								</MDTypography>
							</MDBox>
						</MDBox>
						{(platformType === "Integrate Games in Mobile App" ||
							platformType ===
								"Both, Mobile App and Website") && (
							<MDBox sx={{ mb: 2 }}>
								<TextField
									{...register("mobile_app_url")}
									label="Mobile App URL"
									name="mobile_app_url"
									placeholder="https://play.google.com/store/"
									error={!!errors?.mobile_app_url?.message}
									// required
									fullWidth
								/>
								<MDBox mt={0.75}>
									<MDTypography
										component="div"
										variant="caption"
										color="error"
										fontWeight="regular"
									>
										{errors?.mobile_app_url?.message &&
											`* ${errors?.mobile_app_url?.message}`}
									</MDTypography>
								</MDBox>
							</MDBox>
						)}
						{/* {(platformType === "Integrate Games in Mobile App" ||
							platformType ===
								"Both, Mobile App and Website") && (
							<MDBox sx={{ mb: 2 }}>
								<FormControlLabel
									sx={{ m: 0 }}
									control={
										<Checkbox
											defaultChecked={getValues(
												"both_apple_google"
											)}
											onChange={(e) =>
												setValue(
													"both_apple_google",
													e.target.checked
												)
											}
											name="both_apple_google"
										/>
									}
									label="Check if you have both Appstore and Playstore. Enter any one url"
								/>
								<MDBox mt={0.75}>
									<MDTypography
										component="div"
										variant="caption"
										color="error"
										fontWeight="regular"
									>
										{errors?.both_apple_google?.message &&
											`* ${errors?.both_apple_google?.message}`}
									</MDTypography>
								</MDBox>
							</MDBox>
						)} */}
						{(platformType === "Integrate Games in Website" ||
							platformType ===
								"Both, Mobile App and Website") && (
							<MDBox sx={{ mb: 2 }}>
								<TextField
									{...register("website_url")}
									label="Website URL"
									name="website_url"
									placeholder="https://www."
									error={!!errors?.website_url?.message}
									// required
									fullWidth
								/>
								<MDBox mt={0.75}>
									<MDTypography
										component="div"
										variant="caption"
										color="error"
										fontWeight="regular"
									>
										{errors?.website_url?.message &&
											`* ${errors?.website_url?.message}`}
									</MDTypography>
								</MDBox>
							</MDBox>
						)}
						{platformType ===
							"Don't need integration in App or Website" && (
							<MDBox sx={{ mb: 2 }}>
								<TextField
									{...register("reason")}
									label="Please specify"
									name="reason"
									error={!!errors?.reason?.message}
									// required
									fullWidth
								/>
								<MDBox mt={0.75}>
									<MDTypography
										component="div"
										variant="caption"
										color="error"
										fontWeight="regular"
									>
										{errors?.reason?.message &&
											`* ${errors?.reason?.message}`}
									</MDTypography>
								</MDBox>
							</MDBox>
						)}
						<MDBox sx={{ mb: 2 }}>
							<TextField
								{...register("phone_number")}
								label="Phone number to reach you (recommended)"
								name="phone_number"
								error={!!errors?.phone_number?.message}
								// required
								fullWidth
							/>
							<MDBox mt={0.75}>
								<MDTypography
									component="div"
									variant="caption"
									color="error"
									fontWeight="regular"
								>
									{errors?.phone_number &&
										`* ${errors?.phone_number?.message}`}
								</MDTypography>
							</MDBox>
						</MDBox>
					</Grid>
				</Grid>
			</MDBox>
		</MDBox>
	);
}

export default Platform;
