import { ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "template/examples/LayoutContainers/PageLayout";

// Material Dashboard 2 PRO React page layout routes
// import pageRoutes from "page.routes";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import pageRoutes from "page.routes";

// Declaring props types for IllustrationLayout
interface Props {
	header?: ReactNode;
	title?: string;
	description?: string;
	children: ReactNode;
	illustration?: string;
	bgTitle?: string;
	bgSize?: "cover" | "contain";
	hideNavBar?: boolean;
}

function IllustrationLayout({
	header,
	title,
	description,
	illustration,
	hideNavBar,
	bgSize,
	bgTitle,
	children,
}: Props): JSX.Element {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	return (
		<PageLayout background="default">
			{!hideNavBar && 
				<DefaultNavbar
					routes={pageRoutes}
					action={{
					type: "external",
					route: "https://creative-tim.com/product/material-dashboard-2-pro-react-ts",
					label: "buy now",
					color: "info",
					}}
				/>
			}
			<Grid
				container
				sx={{
				  backgroundColor: ({ palette: { background, light } }) =>
				    darkMode ? background.default : light.main,
				}}
			>
				<Grid item xs={12} lg={6}>
					<MDBox
						display={{ xs: "none", lg: "flex" }}
						width="calc(100% - 2rem)"
						height="calc(100vh - 2rem)"
						borderRadius="lg"
						ml={2}
						mt={2}
						sx={{ backgroundImage: `url(${illustration})`, backgroundRepeat: "no-repeat", backgroundSize: bgSize, backgroundPosition: "center" }}
					>
						{bgTitle && (
							<MDTypography
								variant="h3"
								fontWeight="bold"
								sx={{
									marginTop: '3%',
								}}
							>
								{bgTitle}
							</MDTypography>
						)}
					</MDBox>
				</Grid>
				<Grid
					item
					xs={11}
					sm={8}
					md={6}
					lg={4}
					xl={3}
					sx={{ mx: "auto" }}
				>
					<MDBox
						display="flex"
						flexDirection="column"
						justifyContent="center"
						height="100vh"
					>
						<MDBox py={3} px={3} textAlign="center">
							{!header ? (
								<>
									<MDBox mb={1} textAlign="center">
										<MDTypography
											variant="h4"
											fontWeight="bold"
										>
											{title}
										</MDTypography>
									</MDBox>
									<MDTypography variant="body2" color="text">
										{description}
									</MDTypography>
								</>
							) : (
								header
							)}
						</MDBox>
						<MDBox>{children}</MDBox>
					</MDBox>
				</Grid>
			</Grid>
		</PageLayout>
	);
}

// Declaring default props for IllustrationLayout
IllustrationLayout.defaultProps = {
	header: "",
	title: "",
	description: "",
	illustration: "",
	bgSize: "cover",
	bgTitle: "",
};

export default IllustrationLayout;
