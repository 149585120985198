// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import MDInput from "template/components/MDInput";
import MDButton from "template/components/MDButton";

// Authentication layout components
// import SimpleLayout from "template/layouts/simple-layout/SimpleLayout";

// Authentication layout components
import IllustrationLayout from "template/layouts/authentication/components/IllustrationLayout";

// Image
// import bgImage from "assets/images/illustrations/singup_bg.jpg";
import bgImage from "assets/images/pages/signup.svg";
import googleIcon from "assets/images/icons/google.png";

// Images
// import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { login, signUp } from "actions/auth";
import {
	startFullScreenLoading,
	stopFullScreenLoading,
} from "slices/modalSlice";
import { useForm } from "react-hook-form";
import AuthButton from "components/elements/auth-button";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

const schema = yup
	.object({
		first_name: yup.string().required("No name provided"),
		email: yup
			.string()
			.email("Please enter a valid email")
			.required("No email provided"),
		password: yup
			.string()
			.required("No password provided")
			.min(8, "Password should be 8 characters minimum"),
		/* .matches(
			/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
			"Password should contain at least one letter and one number"
		), */
		password_confirmation: yup
			.string()
			.oneOf([yup.ref("password"), null], "Passwords do not match"),
		checked: yup
			.boolean()
			.oneOf([true], "Please agree to the terms and conditions"),
	})
	.required();

const renderErrorMessage = (message: string = "") => (
	<MDBox display="flex" alignItems="center" ml={1}>
		<MDTypography variant="button" fontWeight="regular" color="error">
			{message}
		</MDTypography>
	</MDBox>
);

function SignUpPage(): JSX.Element {
	const [serverErrors, setServerErrors] = useState<any>(null);
	const [loading, setLoading] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onSubmit = async (data: any) => {
		setServerErrors(null);
		setLoading(true);
		// eslint-disable-next-line camelcase
		const { first_name, email, password } = data;
		try {
			await dispatch(
				// eslint-disable-next-line camelcase
				signUp({ first_name, last_name: "", email, password })
			);
			await dispatch(startFullScreenLoading());
			try {
				await dispatch(login(email, password));
				await dispatch(startFullScreenLoading());
				setTimeout(() => {
					dispatch(stopFullScreenLoading());
					navigate("/dashboard");
				}, 3000);
			} catch (e) {
				// console.log(e);
			}
			await dispatch(stopFullScreenLoading());
			// await dispatch(startFullScreenLoading());
			setTimeout(() => {}, 3000);
		} catch (error: any) {
			setServerErrors(error?.data);
		} finally {
			setLoading(false);
		}
	};

	return (
		<IllustrationLayout hideNavBar illustration={bgImage} bgSize="contain">
			<Card>
				<MDBox
					variant="contained"
					bgColor="primary"
					borderRadius="md"
					coloredShadow="success"
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign="center"
				>
					<MDTypography
						variant="h4"
						fontWeight="medium"
						color="white"
					>
						Create Account
					</MDTypography>
				</MDBox>

				<MDBox
					mt={2}
					mx="auto"
					justifyContent="center"
					textAlign="center"
				>
					<AuthButton
						title="Continue with Google"
						icon={googleIcon}
						authProvider="google"
					/>
				</MDBox>
				<MDBox
					mt={2}
					mx="auto"
					justifyContent="center"
					textAlign="center"
				>
					<MDTypography variant="body2" color="text">
						<b>or</b>
					</MDTypography>
				</MDBox>
				<MDBox shadow="lg" pt={2} pb={3} px={3}>
					<MDBox component="form" role="form">
						<MDBox>
							<MDInput
								autoComplete="off"
								{...register("first_name")}
								type="text"
								label="Name"
								error={errors.first_name}
								fullWidth
							/>
						</MDBox>
						{renderErrorMessage(errors?.first_name?.message)}
						<MDBox mt={2}>
							<MDInput
								autoComplete="off"
								{...register("email")}
								type="email"
								label="Email"
								error={errors.email || serverErrors?.email[0]}
								fullWidth
							/>
						</MDBox>
						{renderErrorMessage(
							errors?.email?.message || serverErrors?.email[0]
						)}
						<MDBox mt={2}>
							<MDInput
								autoComplete="off"
								{...register("password")}
								type="password"
								label="Password"
								error={errors.password}
								fullWidth
							/>
						</MDBox>
						{renderErrorMessage(errors?.password?.message)}
						<MDBox mt={2}>
							<MDInput
								autoComplete="off"
								{...register("password_confirmation")}
								type="password"
								label="Repeat Password"
								error={errors.password_confirmation}
								fullWidth
							/>
						</MDBox>
						{renderErrorMessage(
							errors?.password_confirmation?.message
						)}
						<MDBox
							display="flex"
							alignItems="center"
							mt={2}
							ml={-1}
						>
							<Checkbox {...register("checked")} />
							<MDTypography
								variant="button"
								fontWeight="regular"
								color="text"
								sx={{
									cursor: "pointer",
									userSelect: "none",
									ml: -1,
								}}
							>
								&nbsp;&nbsp;I agree the&nbsp;
							</MDTypography>
							<MDTypography
								component="a"
								href="#"
								variant="button"
								fontWeight="bold"
								color="primary"
								textGradient
							>
								Terms and Conditions
							</MDTypography>
						</MDBox>
						{renderErrorMessage(errors?.checked?.message)}
						<MDBox mt={4} mb={1}>
							<MDButton
								onClick={handleSubmit(onSubmit)}
								color="warning"
								fullWidth
							>
								{loading ? (
									<CircularProgress
										color="inherit"
										size={20}
									/>
								) : (
									"Sign Up"
								)}
							</MDButton>
						</MDBox>
						<MDBox mt={3} mb={1} textAlign="center">
							<MDTypography variant="button" color="text">
								Already have an account?{" "}
								<MDTypography
									component={Link}
									to="/login"
									variant="button"
									color="info"
									fontWeight="medium"
									textGradient
								>
									Login
								</MDTypography>
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		</IllustrationLayout>
	);
}

export default SignUpPage;
